<script setup>
const props = defineProps({
    title: {
        type: String,
        required: true,
    },

    home: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const router = useRouter();

const goBack = () => {
    if (props.home) {
        router.push({ path: '/' });
    } else {
        router.go(-1);
    }
};
</script>

<template>
    <div class="button">
        <i class="pi pi-arrow-left" @click="goBack"></i>
        {{ title }}
    </div>
</template>

<style lang="scss" scoped>
@import 'libs/shared/assets/scss/main.scss';

.button {
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 40px 12px;
    color: $primary-color;
    font-weight: 600;
    font-size: 32px;

    i {
        cursor: pointer;
        font-size: 25px;
        @include dir(rtl) {
            transform: rotate(180deg);
        }
        @include respond-to(tablet) {
            font-size: 20px;
        }
    }

    @include respond-to(tablet) {
        padding: 32px 6px;
        font-size: 18px;
        gap: 16px;
    }
}
</style>
